.header {
  z-index: 5;
  align-items: center;
  background-color: transparent;
  position: absolute;
  right: 0;
}

.content {
  text-align: center;
  min-height: 100vh;
  background-color: #ffffff;
}

.footer-copyright {
  font-size: 0.8em;
  font-style: italic;
}

.orbit-logo {
  position: relative;
  width: 100%;
  height: auto;
  top: 11%;
}

.orbit-icon {
  width: 1.6em;
  height: 1.6em;
  line-height: 1.6em;
  font-size: 1.2em;
  border-radius: 50%;
  text-align: center;
  display: block;
}

.orbit-wrap {
  height: 100%;
  width: 100%;
  list-style: none;
  font-size: 30px;
}

.orbit-wrap > li {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

ul[class^="ring"] {
  transition: all 300ms ease-in-out;
}
ul[class^="ring"] li {
  transition: all 300ms ease-in-out;
}
.ring-0 {
  width: 25em;
  height: 25em;
  animation: clockwiseRotate 30s linear infinite;
}
.ring-0 > *:nth-of-type(1) {
  transform: rotate(5deg) translate(12.4em);
}
.ring-0 > *:nth-of-type(2) {
  transform: rotate(190deg) translate(12.4em);
}

.ring-1 {
  width: 20em;
  height: 20em;
  animation: clockwiseRotate 40s linear infinite;
}
.ring-1 > *:nth-of-type(1) {
  transform: rotate(130deg) translate(9.8em);
}
.ring-1 > *:nth-of-type(2) {
  transform: rotate(260deg) translate(10em);
}

.ring-2 {
  width: 15em;
  height: 15em;
  animation: clockwiseRotate 10s linear infinite;
}
.ring-2 > *:nth-of-type(1) {
  transform: rotate(47.5deg) translate(7.5em);
}
.ring-2 > *:nth-of-type(2) {
  transform: rotate(185deg) translate(7.5em);
}

ul[class^="ring"] {
  border: solid 1px #727171;
  opacity: 0.6;
  position: relative;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  box-sizing: content-box;
}

ul .ring-0 li {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.6em;
  height: 1.6em;
  margin: -0.85em;
}

ul .ring-1 li {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.6em;
  height: 1.6em;
  margin: -0.6em;
}

ul .ring-2 li {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.6em;
  height: 1.6em;
  margin: -0.9em;
}

/* center */
.orbit-center {
  z-index: 5;
  width: 500px;
  height: 500px;
  text-align: center;
  transform: rotateZ(-360deg);
  transition: all 300ms ease-in-out;
}

/* animations */
@keyframes clockwiseRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes counterClockwiseRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.fa-test0 {
  background: #9ad5e2;
  color: white;
}
.fa-test1 {
  background: #a8497c;
  color: white;
}
.fa-test2 {
  background: #b497b5;
  color: white;
}
